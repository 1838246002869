.experience_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

}
.experience_frontend{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.experience_backend{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.experience_container>div{
    background: var(--color-bg-variant);
    padding: 1rem 3.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}
.experience_container>div:hover{
    transition-duration: 1s;
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor:default;
}
.experience_container > div h3{
    margin-bottom: 2rem;
    color:var(--color-primary);
    font-size: 2.0rem;
    text-align: center;
    text-decoration:overline;
}

.experience_content{
    display: flex;
    grid-template-columns: 1fr 1fr;
}
.text{
    color: turquoise;
    font-style:oblique;

}
.icon{
    font-size: 2.3rem;
    margin-left: .5rem;
    margin-top: 1.3rem;
    color:var(--color-primary);
}
.experience_details{
    display: flex;
    gap:1rem;
}


@media screen and (max-width: 1024px){
    .experience_container{
        grid-template-columns: 1fr;
    }
    .experience_container >div{
        width: 60%;
        padding: 1rem;
        margin: 0 auto;
    }
    .experience_content{
        padding: 1rem;
    }
}

@media screen and (max-width: 600px){
    .experience_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95vw;
        margin: 0 auto; /* Centers the container horizontally */
    }
    .experience_container > div{
        width:100%;
        padding: 1.5rem 0.55rem;
    }
    .experience_container > div h4{
            font-size: 1.6rem;

    }
    .icon{
        font-size: 1.9rem;
    }    

}