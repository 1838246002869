header{
    height:120vh;
    padding-top:12rem;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}
section > h5{
    font-size: 3rem;
}
/* *************** CTA **************** */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
    opacity:initial;
    transition: transform 250ms,opacity 10000ms;

}
.cta a{
    width: 13rem;
    height:5rem;
    text-align: center;
    line-height: 3.2rem;
    border-color: transparent;
    font-size: 1.1rem;
    backdrop-filter: blur(18px);
    color: white;
    border: 3px dashed var(--color-primary);
}
.cta a:hover{

    transform: scale(1.2);
    background:transparent;
    backdrop-filter: blur(3px);
    color: white;

}

.header_socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    transform: scale(1.6);

}
.header_socials:hover{
    transition-delay: 100ms;
    transform: scale(1.4);

}
.header_socials::before{
    content: '';
    width: 1px;
    background: var(--color-primary);

}


.me{
    background: var(--color-primary);
    border-radius: 1.8rem;
    width: 29rem;
    height: 41rem;    
    position: absolute;
    display: flex; /* Using flex to center the image */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    overflow-y:hidden;
    left: 50%;
    transform: translateX(-50%); 
}
.me img {
    width: 100%; /* Image will scale to the width of the container */
    height: 100%; /* Height will adjust automatically to maintain the aspect ratio */
}
.scroll_down{
    transform: rotate(90deg);
    font-weight:300;
    font-size: 0.8rem;
    
    transition: transform 250ms;

}
.scroll_down:hover{
    transition-duration: 1000ms;
    transform:scale(1.4);

}
@media screen and (max-width: 1024px){
    header{
        height: 100vh;
        overflow: hidden;
    }
}

@media screen and (max-width: 600px){
    header{
        height: 100vh;
        overflow: hidden;
    }
    .header_socials,
    .scroll_down{
        display: none;
    }
    
}