nav{
    background:  rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    width: max-content;
    display: block;
    padding:  0.7rem 1.7rem;
    z-index: 2;
    position:fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 3rem;
}
nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-white);
    font-size: 1.1rem;
}

nav a:hover{
    background: rgba(0, 0, 0, 0.3);
    transform: scale(1.2)
}

nav a.active{
    background: var(--color-bg);
    opacity: 50%;
    color: var(--color-white);
    transition: transform 200ms ;

}