@import url('https://fonts.googleapis.com/css2?family=Charis+SIL&family=Poppins:wght@300;400;500;600&family=Source+Sans+Pro&display=swap');


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

}
:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4da0ff;
    --color-primary-variant: #rgba(77,181,255,.4);
    --color-white: #fff;
    --color-light:gray ;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    
}
html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    display: none;
}
.dark-mode{
    font-family: 'Source Sans Pro', sans-serif;    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.jpg);
}
.light-mode{
    font-family: 'Source Sans Pro', sans-serif;    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture-light.jpg);
}

/* *************************General Styles ************************** */
.container{
width: var(--container-width-lg);
margin:0 auto;
};


h1,h2,h3,h4,h5{
    font-weight: 500;
}
h1{
    font-size: 6rem;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}
h3{
    font-size: 2.5rem;
}
h4{
    font-size: 2.5rem;
}
h5{
    color:white;
}
section{
    margin-top: 8rem;
}
section > h2, section > h5{
    text-align: center;
    font-size: 5rem;
}

section > h2{
    color: var(--color-primary);
    text-decoration:overline;
    margin-bottom: 3rem;

}
section > h5{
    padding-left: 15%;
    text-align: center;
    text-decoration: underline;
    
}
.text-light{
    color: var(--color-light);
}
a{
    color:var(--color-primary);
    transition: var(--transition);
}
a:hover{
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: .75rem 1.2rem;
    border-radius: 0.4rem;
    cursor:pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
    margin-top :3rem;

}

.MAIN{
    background: var(--color-primary);
    color: var(--color-bg);
    border-radius: 15rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2rem;

}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/******************** Media Queries (Medium Devices) ******************* */
@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }
    section{
        margin-top: 6rem;
    }
}

/******************** Media Queries (Small Devices) ******************* */
@media screen and (max-width: 600px){
    .container{
        width: var(--container-width-sm);
    }
    section > h2{
        margin-top: 2rem;
    }
}