.container.contact_container{
    width: 66%;
    display: grid;
    grid-template-columns:30% 58%;
    gap:12%;

}
.contact_options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact_option{
    background:var(--color-bg-variant);
    margin-left: -7rem;
    margin-right: 7rem;
    padding: 1.2rem;
    text-align: center;
    border-radius: 2.6rem;
    border: 1px solid transparent;
    transition:var(--transition);
    display:list-item;
    ;
}
.contact_option:hover{
    background: transparent;
    transition-duration: 1.2s;
    transform:translateX(2rem);
    border-color: var(--color-primary-variant);
}
.contact-option-icon{
    font-size: 2.2rem;
    color:var(--color-primary);
    margin-bottom: -1rem;
}
.contact_option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.9rem;
}
.contact_option h5{
    color: var(--color-light);
    font-size: 1.25rem;
}

/*FORM STLYING*/
form{
    display: flex;
    flex-direction: column;
    scale:1.15;
    transform:translateY(3rem);
    gap:1.6rem;
    padding-left: 2rem;

}
input,textarea{
    width:130%;
    margin-left: -8rem;
    padding:1.5rem;
    border-radius: 1.7rem;
    background: transparent;
    border: 4px solid lightblue;
    resize: none;
    color: white;
}

.sub{
    height:3.3rem;
    text-align: center;
    font-size: 1.1rem;
    text-align: right;
    background-color: var(--color-);
    backdrop-filter: blur(12px);
    transform: translateX(21rem);
}
.sub:hover{
    background:transparent;
    backdrop-filter: blur(3px);

}
@media screen and (max-width: 1024px){
    .container.contact_container{
        grid-template-columns: 1fr ;
        gap: 2rem;
    }
}
@media screen and (max-width: 600px){
    .container.contact_container{
        width:var(--container-width-sm);
        position: center;
        
    }
    form{
        display: flex;
        flex-direction: column;
        scale:1;
        transform:translateY(0rem);
        gap:1.2rem;
        padding-left: 2rem;
    
    }
    input,textarea{
        width: 100%;
        margin-left: 0rem;

    }
    .contact_option{
        background:var(--color-bg-variant);
        margin-left:0rem;
        margin-right: 0rem;
        padding: 1.2rem;
        text-align: center;
        border-radius: 2.6rem;
        border: 1px solid transparent;
        transition:var(--transition);
    }
    .sub{
        transform: translateX(0);
    }

}

