
.work {
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    font-size: 6rem;
    margin-bottom: 5%;
    justify-content: center; /* Center the cards horizontally */
    align-content: center; /* Center the cards vertically */
}

.workpos {
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;
    font-size: 3.5rem;
    margin-bottom: 5%;
    justify-content: center; /* Center the cards horizontally */
    align-content: center; /* Center the cards vertically */
}

.work-image{
    border-radius: 2rem;
    overflow: hidden;
    max-width: 11rem;
    max-height: 11rem;
    justify-items: left;
    display: flex;

}

.portfolio_item{
    padding: 0.7rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition)

}

.portfolio_item:hover{
    transition-duration: 1s;
    border-color: var(--color-primary-variant);
    background: transparent;
}
.portfolio_workitem{
    background-color: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    font-size: 5rem;
    justify-items: center;
    margin-bottom:3rem;
    width: 80%;
    border: 1px solid transparent;
    transition: var(--transition);

}

.portfolio_workitem:hover{
    transition-duration: 1s;
    border-color: var(--color-primary-variant);
    background: transparent;
}

@media screen and (max-width: 1024px){
    .work{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}
@media screen and (max-width: 600px) {
    .work {
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;
    }
    .workpos {
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio_workitem {
        margin-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio_item {
        padding: 1rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
    div h4 {
        font-size: 2rem;
        text-align: center;
    }
    .work-option-icon {
        font-size: 4rem;
        justify-self: center;
    }
}
