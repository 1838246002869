footer{
    background: black;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}
footer a{
    color: rgb(116, 116, 157);
}
.footer_logo{
    font-size:1.2rem;
    font-weight: 500;
    margin-bottom:1rem;
    transform: scale(1.5);
    display:inline-block;
}
.footer_socials{
    display: flex;
    justify-content: center;
    gap:3rem;
    margin-bottom: 4rem;
}
.footer_socials a{
    background: var(--color-bg);
    color: rgb(116, 116, 157);
    padding:1rem;
    border-radius: 0.9rem;
    display: flex;
    transform: scale(1.5);
    border: 1px solid transparent;
}
.footer_socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color:var(--color-bg);
}
.footer_copyright{
    margin-bottom: 4rem;
    color: rgb(116, 116, 157);
}
li{
    margin: 1rem;
}
.permalinks{
    display:grid;
    flex-wrap:wrap;
    justify-content: center;
    padding-bottom: 1rem;
    grid-template-columns: repeat(2, 0.09fr);
}
.permalinks :hover{
    transform: scale(1.2);
    transition-duration: 800ms;
}