.portfolio_item-image{
    border-radius: 1.5rem;
    max-height: 10rem;
    overflow: hidden;
}
.portfolio_container{
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 5%;
}


.portfolio_item{
    display: grid;
    background: var(--color-bg-variant);
    padding: 1.3rem;
    max-height: 27rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover{
    transition-duration: 1s;
    transform: scale(1.1);
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-video{
    height:17rem;
    border-radius: 1.5rem;
    width: 22rem;
    overflow: hidden;
}

.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}

@media screen and (max-width: 1024px){
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}
@media screen and (max-width: 600px){
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .portfolio_item{
        display: grid;
        background: var(--color-bg-variant);
        padding: 1.3rem;
        max-height:fit-content;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);
    }
    
}