.about_container{
    display: grid;
    grid-template-columns: 29% 50%;
    gap: 8%;
}
.modal{
    text-align: center;
    align-items: center;
    background-color:transparent;
    border-radius: 7rem;
    backdrop-filter: blur(60px);
    padding: 4rem;
    font-size: 1.5rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.about_me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
}
.about_me-image{
    border-radius:2rem;
    overflow:hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
.about_me-image:hover{
    transform: rotate(0deg);
}

.about_cards{
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    width: 110%;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-bottom: 5%;
}
.about_card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding:2rem;
    text-align:center;
    transition:var(--transition),transform 400ms;
    
}
.about_details{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding:2rem;
    text-align:left;
    font-size: 1.31rem;
    transition:var(--transition),transform 400ms;

    
}
.bullet{
    color: turquoise;
    font-size: 1.4rem;
    margin-right: .28rem;
}
h2{
    text-align: center;
    font-size: 5rem;
    color: var(--color-primary);
    text-decoration:overline;
    margin-bottom: 3rem;
}
h5{
    font-size: 5rem;
    text-align: center;

}
.about_card:hover{
    transform: scale(1.2);
    transition-duration: 800ms;
    background:transparent;
    border-color: var(--color-primary-variant);

}

.btn-primary:hover{
    transform: scale(1.15);
    transition-duration: 1000ms;

}
.about_icon{
    color: turquoise;
    font-size: 1.4rem;
}
.about_card h5{
    font-size: .95rem;
}
.about_card small{
    font-size: 0.85rem;
    color: turquoise;
}
.about_content p{
    margin: 2rem 0 2.6rem;
    font-size:larger;
    color: turquoise
}
@media screen and (max-width: 1024px){
    .about_container{
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about_me{
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about_content p{
        margin: 1rem 0 1.5rem;
    }
    .modal{
        font-size: .9rem;
        scroll-behavior: auto;
    }
    .pop_up_window {
        max-width: 100vw;
    }
}
.pop_up_window {
    max-width: 60vw;
    justify-self: center;
}
@media screen and (max-width: 600px){
    .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    }
    .about_cards{
        grid-template-columns: 1fr;
        width:85%;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
        text-align: left;
        vertical-align: middle;
    }
    .about_content{
        text-align: center;
    }
    .about_content p{
        margin: 1.5rem 0;
    }
    .modal{
        font-size: .9rem;
        scroll-behavior: auto;
    }
    .pop_up_window {
        max-width: 100vw;
    }
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
}